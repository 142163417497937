.react-bootstrap-table table {
    table-layout: auto !important; }

.download-btn {
    float: right;
    margin: 12px 0px;
    padding: 0.5rem 1rem;
}

.teacher-select{
    margin-left: 24px;
    margin-right: 12px;
    margin-top: -6px;
    display: inline-block;
    font-size: 12px;
    width: 200px;
}