.homecoach {
  font-size: 18px;
  &--tutor-row {
    margin: 12px;
    border-bottom: 1px solid gray;
    padding: 8px;
    width: 100%;
  }
  &--tutor-name {
    display: inline-block;
    width: 200px;
    font-weight: bold;
    /* color: #17a2b8; */ /* TM blue */
    color: #1096b0;       /* darker than TM blue */
  }
  &--tutor-name:hover {
    color: black;
    cursor:pointer;
    }

  &--tutor-count {
    display: inline-block;
    width: 220px;
  }

  &--tutor-coach-name {
    float: right;
  }

  &--tutor-table {
    margin-left: 40px;
    margin-top: 12px;
  }

  &--tutor-table table thead tr th {
    text-align: center;
  }
  &--caret-right {
    cursor: pointer;
    transition: 0.5s;
    transition-timing-function: ease;
  }
  &--caret-down {
    rotate: 90deg;
    transition: 0.5s;
    transition-timing-function: ease;
  }
  &--list-panel {
    overflow: hidden;
    min-height: 0px;
    max-height: 0px;
    transition: 0.5s;
    transition-timing-function: ease;

  }
  &--list-panel-open {
    overflow: auto;
    min-height: 80px;
    max-height: 600px;
    transition: 0.5s;
    transition-timing-function: ease;
  }
}
