.App {
    margin-top: 15px;
    max-width: 100%;
  }
  
  .App .navbar-brand {
    font-weight: bold;
    margin-top: -10px !important;
  }

  .Page {
      min-height: 72vh;
      margin: 100px 24px 12px;

  }

  .Footer {
      margin-top: 48px;
      border-top: 1px solid #ddd;
      padding: 24px;
      text-align: center;
      font-size: 14px;
      color: #888;
  }

  .copyright {
      margin-top: 12px;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
  }

  .version {
      float: right;
      font-size: 12px;
  }

  .header-cell {
    text-align: left !important;
    padding-left: 8px !important;
  }

  .cell-hover {
    padding-left: 8px;
  }

  .cell-hover:hover {
    color: #3fc9c4;
    cursor: pointer;
  }

  .btn-vert {
      margin-bottom: 12px;
  }

  .btn-admindash {
      min-width: 214px;
  }

  .cascasdingLeftNav {
      display: inline-block;
      width: 240px;
      color: #8d8c8c;
      font-size: 16px;
      padding: 18px 8px;
      border-right: 1px solid #e6e4e4;
      min-height: 100vh;
  }

  .contentWithLeftNav {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 240px);
  }

/* Enabled */
.custom-control-input:checked~.custom-control-label::before
{
    border-color: #17a2b8 !important;
    background-color: #17a2b8 !important;
}

.custom-switch .custom-control-label::after {
    background-color: white !important;
}

.custom-control-input~.custom-control-label::before
{
  border-color: #17a2b8 !important;
  background-color: #17a2b8 !important;
}

/* Disabled */
.custom-control-input:checked:disabled~.custom-control-label::before
{
  border-color: yellow !important;
  background-color: green !important;
}