.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander p {
    color: #999;
  }

  .active-nav {
    text-decoration: solid;
  }

  .Home .notes h4 {
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Home .notes p {
    color: #666;
  }

  .Home .lander div {
    padding-top: 20px;
  }
  .Home .lander div a:first-child {
    margin-right: 20px;
  }

.talk-moves {
  display: inline;
  font-weight: bolder;  
}

.keep-together {
  color: #f6cc46;
}

.students-relate {
  color: #fae5a2;
}

.restate {
  color: #3dc9c4;
}

.revoice {
  color: #9ee4e1;
}

.accuracy {
  color: #ff7359;
}

.reasoning {
  color: #ffb9ac;
}

.teacher {
  color: #c196d6;
}

.student {
  color: #845998;
}

.community {
  color: #f6cc46;
}

.discussion {
  color: #3dc9c4;
}

.thinking {
  color: #ff7359;
}


.status-uploading-background {
  background-color: #efdef7;
  border: 1px solid #efdef7;
  width: fit-content;
  display: inline;
  margin-left: 12px;
  padding: 4px 6px;
  border-radius: 5px;
}

.status-uploading {
  font-size: 5px;
  vertical-align: middle;
  color: #845998;
  padding-right: 5px;
}

.status-uploaded-background {
  background-color: #FEEEE1;
  border: 1px solid #FEEEE1;
  width: fit-content;
  display: inline;
  margin-left: 12px;
  padding: 4px 6px;
  border-radius: 5px;
}

.status-uploaded {
  font-size: 5px;
  vertical-align: middle;
  color: #FFB273;
  padding-right: 5px;
}


.status-in-progress-background {
  background-color: #FEF7E1;
  border: 1px solid #FEF7E1;
  width: fit-content;
  display: inline;
  margin-left: 12px;
  padding: 4px 6px;
  border-radius: 5px;
}

.status-in-progress {
  font-size: 5px;
  vertical-align: middle;
  color: #EDCB5E;
  padding-right: 5px;
}

.status-analyzing-background {
  background-color: #DFF7F6;
  border: 1px solid #DFF7F6;
  width: fit-content;
  display: inline;
  margin-left: 12px;
  padding: 4px 6px;
  border-radius: 5px;
}

.status-analyzing {
  font-size: 5px;
  vertical-align: middle;
  color: #79D3CF;
  padding-right: 5px;
}

.status-completed-background {
  background-color: #E5EFFF;
  border: 1px solid #E5EFFF;
  width: fit-content;
  display: inline;
  margin-left: 12px;
  padding: 4px 6px;
  border-radius: 5px;
}

.status-completed {
  font-size: 5px;
  vertical-align: middle;
  color: #87B7FF;
  padding-right: 5px;
}