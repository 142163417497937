.LoaderButton {
  margin: 15px 0px;
  width: 100%;
}
.LoaderButton .spinning.glyphicon {
    top: 2px;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }