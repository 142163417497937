.Page {
    margin: 76px 32px 12px;
}

.checkbox-wrapper {
  margin-top: 18px;
  margin-bottom: 12px;
}

.checkbox-checkbox {
  margin-right: 12px;
}

.password-prompter {
  position: absolute;
  left: 66%;
  top: 430px;
  width: 200px;
  min-height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 14px;
  padding: 8px;
  &-inside {
    margin: 12px;
  }
}

.tm-label {
  margin: 12px 11px 2px;
  font-weight: 500;
}

.tm-report-download {
  float: right;
  margin-right: 24px;
}

.tm-report-progress {
  height: 150px;
  width: 300px;
  margin: auto;
  padding-top: 70px;
}

.tm-report-percentage {
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
}