.lessons-page {
    min-height: 100vh;
}
.video-fixed-nav {
    position: fixed;
    z-index: 100;
    background-color: #f6f6f6;
    top: 103px;
    width: 300px;
    border-right: 1px solid #edecec;
    min-height: calc( 100vh - 100px);
    padding: 30px 12px;
}

.lessons-content-area {
    margin-left: 330px;
    width: calc( 100% - 330px);
}

.video-topmenu {
    margin-top: 24px;
    cursor: 'pointer';
    /* color: rgb(246, 204, 70); */ /* <-- the TalkMoves yellow */
   color: rgb(141, 117, 37);  /* just a little darker for more contrast */
}

.video-topmenu:hover {
    font-weight: 400px;
    /* color: #474B4E; */
}

.active{
    font-weight: 600px;
    color: #17a2b8;
}
.video-menuitem {
    /* paint text little darker than the topmenu because the text is smaller */
    color: rgb(121, 100, 32);  
    cursor: pointer;
    margin-left: 24px;
    margin-top: 18px;
}

.video-topmenu:hover, .video-menuitem:hover {
    /* color: rgb(169, 168, 168); */
    /* color: black; */
    color: #106b7a;
    cursor:pointer;
    }



.video-topmenu .selected, .video-menuitem .selected {
    color: #07363d;
}

.lesson-CTA {
    font-size: 20px;
}

.lesson-CTA-button {
    padding: 8px 48px;
}

.lesson-CTA-link {
    display: inline-block;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}

.lesson-CTA-link:hover {
    color: #0164ce;
}

.lesson-panel-row {
    padding-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
}

.lesson-panel-toprow {
    margin-top: 6px;
    padding-bottom: 16px;
    margin-bottom: 24px;
}

#ttm-am-i .yAxis text:hover {
    cursor: pointer;
    text-decoration: underline;  
    font-weight: bold;
}

#stm-am-i .yAxis text:hover {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  
}
.trend-content .xAxis text:hover {
   cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
   
  
}
  .lesson-feedback {
    padding-bottom: 80px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 36px;
  }
  .lesson-feedback .amount-footnote{
    width: 75%;
    margin: auto;
    padding: 1rem;
    font-size: .825rem;
}

.lessons-hover-tip {
    position: absolute;
    background: #f8f8f8cc;
    padding: 18px;
    color: #333;
    min-height: 100px;
    width: 250px;                           
    font-size: 16px;
}


tr:nth-child(odd) {
    background-color: #f3dfab;
}

tr:nth-child(even) {
    background-color: #f3eAd3;
}

th {
    background-color: #dddddd;
}  

  


