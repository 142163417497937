.center {
    text-align: center;
}

.page-header {
    font-size:20px;
    margin: 20px 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    color: #474B4E;
}

body {
    background-color: #f6f6f6;
    color: #474B4E;    
}

.icon {
    padding: 0 5px 0 0;
}

.arrow-icon {
    padding: 0 8px 0 0;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.link {
    font-weight: bolder;
    color: #0069B4;
}

.folder {
    margin-top: 10px;
    margin-left: 50px;
}

.add-a-period {
    width: 200px;
    margin: 0 auto;
}

.btn-blue {
    background-color: #0069b4;
    border-color: #0069b4;
    font-weight: 900;
}

.card-body {
    box-shadow: 2px 2px 5px #C8C8C8;
    border-radius: 2px;
    outline-color: white;
}

.title {
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    font-size: 18px; 
}

.recharts-text {
    font-family: 'Roboto', sans-serif;
    color: #474B4E;    
}

.anchor-margin {
    top: 100px;
}

@media only screen and (max-width: 1338px) {
    .anchor-margin {
        top: 200px;
    }
  }
