.react-bootstrap-table table {
    table-layout: auto !important; }

.download-btn {
    float: right;
    margin: 12px 0px;
    padding: 0.5rem 1rem;
}

.videoPlay {
    color: white;
    width: 60px;
    height: 60px;
    background: rgba(136, 136, 136, 0.533);
    border-radius: 50%;
    padding: 19px;
    padding-left: 13px;
    font-size: 107px;
    line-height: 24px;
    margin-top: 49px;
    margin-left: 148px;
    cursor: pointer;
}

.videoPlay:hover {
    text-decoration: none !important;
    color: darkblue;

}

.tmTable tr td {
    border: 1px solid lightslategray;
    padding: 8px;
}

.definitionContainer {
    width: 90%;
    margin: auto; 
    text-align: center; 
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #dddddd;
}

.flipH {
    display: inline-block;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .leftFaq{
    border-right: 1px lightgray solid;
    line-height: 1.7;
    cursor: pointer;
  }
  .greyText{
    color: #242424;
  }
  .blueText{
    color: #17a2b8
  }
  .leftFaq h4{
    font-family: Open Sans, sans-serif;
    padding: .5rem; 
    text-align: center;
    font-size: 1.25rem;
    border-bottom: 1px lightgray solid;
  }
  .leftFaq h4:hover{
    color: rgba(0,0,0,.5);;
  }
  .leftFaq h4:last-child{
    border: none;
  }
  .leftNavBar {
    border-right: 1px lightgray solid;
    line-height: 1.7;
  }
  .leftNavBar h4{
    color: #242424;
    cursor: pointer;
    padding: 1rem; 
    text-align: center
  }
 
  .questionContent{
    margin-bottom: 2rem;
  }
  #videos .media{
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  #videos .media-body{
    padding: 0 2rem;
  }
  .flex-column-nowrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .grey-btm-border{
    border-bottom: 1px lightgrey solid;
    padding-bottom: 2rem;
    margin-top: 2rem;
  }
  .green-btm-border{
    border-bottom: 2px #3FC9C4 solid;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .reading-cover{
    max-width: 360px;
  }
  .text-small-narrow{
    font-size: 10px; 
    max-width: 360px;
    padding: 1rem 1rem;
  }
  .quoteShadow{
    -webkit-box-shadow: 10px 10px 36px 0px rgba(196,196,196,0.8);
    -moz-box-shadow: 10px 10px 36px 0px rgba(196,196,196,0.8);
    box-shadow: 10px 10px 36px 0px rgba(196,196,196,0.8);
  }
  .heavy{
    font-weight: 700;
  }
  .padding-left-three{
    padding-left: 3rem;
  }
  .round{
    border-radius: 12px;
  }
  .round-left{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .vid-link{
    width: 360px;
    height: 100%;
  }
  .light-grey{
    background-color: #e9ecef
  }
  .margin-auto{
    margin: auto;
  }
  .align-center{
    text-align: center;
  }
  .headline{
    padding: 2rem;
    margin: 1rem 0 2rem 0;
  }
  .headline-even{
    padding: 2rem;
    margin: 2rem 0;
  }
  .headline-image{
    display: block;
    margin: auto; 
    max-width: 300px; 
    padding: 2rem;
  }
  .sans{
    font-family: Open Sans, sans-serif;
  }
  .callout-blue{
    background-color:#2f9ca7cf;
    padding: 2rem; 
    margin-bottom: 1rem; 
    color: #ffffff;
    font-family:Open Sans, sans-serif;
  }
  #img-swivl-comp{
    width: 100%;
    max-width: 700px;
    margin: 2rem;
  }
  .cu-logo{
    display: block;
    max-width: 450px;
    margin: auto;
  }
  .content{
    padding: 0 2rem;
  }
  .single-column{
    margin: auto;
    width: 100%;
    max-width: 800px;
  }
  .record-img-margin{
    margin:25px;
  }
  .anchor{
    display: block;
    height: 95px; /*same height as header*/
    margin-top: -95px; /*same height as header*/
    visibility: hidden;
  }
  @media (max-width: 575.98px) { 
    .cu-logo{
      max-width: 300px;
      margin: auto;
    }
    .content{
      padding: 0;
    }
    .record-img-margin{
      margin: .5rem;
    }
    .single-column{
      margin: auto;
      width: 100%;
      padding: .5rem;
    }
  }