
.calendar-column {
    width: 200px;
    padding: 24px 12px;
    display: inline-block;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 24px 0px;
    vertical-align: top;
}

.calendar-row {
    display: inline-block;
    width: 100%;
    padding: 24px 12px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 12px 0px;
    padding-top: 8px;
}

.calendar-item {
    display: inline-block;
    margin-right: 48px;
    margin-top: 8px;
}

.calendar-input-box {
    width: 100px;
}

.trend-header {
    display: inline-block;
}
.trend-content {
    width: 100%;
    /* width: calc(100% - 250px); */
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 24px;
    display: inline-block;
    vertical-align: top;
}

.trend-pullquote {
    color: #17a2b8;
    font-style: italic;
    font-size: 24px;
    margin-top: 48px;
    width: 80%;
    line-height: 40px;;
}

.leftTab {
    text-transform: uppercase;
    width: 100%;
    margin: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #dddddd88;
    cursor: pointer;
}

.leftTab:hover {
    color: black;
}
