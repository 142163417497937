.mainLink {
    margin-right: 18px;
}

.dropMenuContainer {
    position: absolute;
    background: white;
    padding: 2px 12px;
    font-size: 16px;
    line-height: 28px;
    margin-top: -7px;
    margin-left: 7px;
    min-width: 180px;
    border: 1px solid #cfcdcd;
    border-radius: 3px;
    z-index: 1000;
}

.dropLinkLast {
    border: none;
}

.dropLink {
    border-bottom: 1px solid #cfcdcd;
}

.dLinkColor {
   color: #17a2b8
}

.dLinkColor:hover {
    color: #0c5864
 }