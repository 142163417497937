@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
    margin-bottom: 12px;
  }

  .signup-field {
      margin-bottom: 12px;
  }

  .signup-field-error {
    margin-bottom: 12px;
}